import * as React from "react";
import Layout from "common/components/Layout";
import Seo from "components/seo";
import NewCustomer from "containers/NewCustomer";
import PartnerWrapper from "../partner.style";
import Humberto from "common/assets/image/partners/american-profit-consulting/humberto.png";
import IWALogo from "common/assets/image/partners/iwa/iwa.png";

const CustomerIntakePage = ({ location }) => {
    return (
        <Layout location={location.pathname} hasContact={false}
            minimalNav={true}>
            <Seo title="Global Message ERC: New Business Intake Form" />
            <PartnerWrapper>
                <div className="Partner-info-no-photo">
                    <div className="contact-info">
                        <h3>Global Message LLC</h3>
                        <p className="license"><b>Authorized Partner: Mitch Sodikoff</b><br/>
                        <h4>Phone: (661) 645-7373<br/>
                        mitch@theglobalmessage.com</h4>
                        </p> 
                        <a href="https://theglobalmessage.com/" target="gml">https://theglobalmessage.com</a>
                        
                    </div>
                </div>
            </PartnerWrapper>
            <NewCustomer referrer="HdlOClMcBb1rI0"/>
        </Layout>
    );
};

export default CustomerIntakePage;